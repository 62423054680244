let globalsDone = false;

export default function initAccordions(setupHandlers) {
	let accordionsEls = document.querySelectorAll("[data-accordion]");
	const controlAll = document.querySelector("[data-accordion-control-all]");

	if (accordionsEls.length > 0) {
		openAllSwitch(controlAll);
		accordionsEls.forEach($acc => {
			let $triggers = $acc.querySelectorAll("[data-accordion-trigger]")
			let checkControlAll = () => {
				const anyOpen = Array.from($triggers).some($el => {
					return attrBool($el.getAttribute("aria-expanded"));
				});
				if (anyOpen) {
					closeAllSwitch(controlAll);
				} else {
					openAllSwitch(controlAll);
				}
			}
			$triggers.forEach($t => {
				const contentId = $t.getAttribute("aria-controls");
				const $content = $acc.querySelector(`#${contentId}`);
				$content.querySelectorAll('video').forEach($video => {
					$video.addEventListener('loadeddata', _ => {
						setAccordionHeights($content);
					})
				})
				setAccordionHeights($content);
				if (setupHandlers) clickHandlers($t, $content, checkControlAll);
			})
		});
		if (!globalsDone) {
			globalsDone = true;
			window.addEventListener("resize", _ => {
				initAccordions(false);
			});
			controlAll.addEventListener('click', (e) => {
				e.preventDefault();
				// This will need to change if there's ever two accordions on the same page
				accordionsEls.forEach($acc => {
					const closeAll = controlAll.classList.contains('close');
					$acc.querySelectorAll(`[data-accordion-trigger][aria-expanded="${closeAll}"]`).forEach($trigger => $trigger.click())
					if (closeAll) {
						openAllSwitch(controlAll);
					} else {
						closeAllSwitch(controlAll);
					}
				});
			});
		}
	}

}

function closeAllSwitch($controlAll) {
	$controlAll.querySelector('span').innerText = 'Close all';
	$controlAll.classList.add('close');
}

function openAllSwitch($controlAll) {
	$controlAll.querySelector('span').innerText = 'Open all';
	$controlAll.classList.remove('close');
}

function setAccordionHeights($content) {
	const opened = $content.parentNode.classList.contains("is-active");
	$content.style.setProperty("--accordion-height", "auto");

	$content.classList.remove("is-closed");

	$content.style.setProperty(
		"--accordion-height",
		$content.offsetHeight + "px"
	);
	if (!opened) {
		$content.classList.add("is-closed");
	} else {

	}
}

function clickHandlers($trigger, $content, checkControlAll) {
	$trigger.addEventListener("click", e => {
		const ariaHiddenValue = attrBool($content.getAttribute("aria-hidden"));
		const ariaExpandedValue = attrBool($trigger.getAttribute("aria-expanded"));

		$trigger.parentNode.classList.toggle("is-active");
		$trigger.setAttribute("aria-expanded", !ariaExpandedValue);
		$content.setAttribute("aria-hidden", !ariaHiddenValue);
		$content.classList.toggle("is-closed");
		e.preventDefault();
		checkControlAll();
	});
}

function attrBool(attrValue) {
	return attrValue === "true" ? true : false;
}
