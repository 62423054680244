export default function initCurriculumBlock() {
	document
		.querySelectorAll(".l-curriculum-block")
		.forEach($c => curriculumBlock($c));
}

function curriculumBlock($container) {
	const $moduleSelector = $container.querySelector(
		".l-curriculum-block__module-chooser-control"
	);
	if (!$moduleSelector) {
		return
	}
	const $moduleData = Array.from($container.querySelectorAll("[data-module]"));
	$moduleSelector.addEventListener("change", e => {
		const moduleId = e.target.value;
		$moduleData.forEach($m => {
			$m.classList.remove("is-visible");
			if ($m.dataset.module === moduleId) {
				$m.classList.add("is-visible");
			}
		});
	});
}
