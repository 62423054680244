/* message signatures
export interface RenderBlocksRequestMessage extends SimulatorMessage {
    type: "renderblocks",
    id: string;
    code: string;
    options?: {
        package?: string;
        packageId?: string;
        snippetMode?: boolean;
    }
}

export interface RenderBlocksResponseMessage extends SimulatorMessage {
    source: "makecode",
    type: "renderblocks",
    id: string;
    svg?: string;
    width?: number;
    height?: number;
}*/

import resetAccordions from './accordion';


function injectRenderer() {
    var f = document.getElementById("makecoderenderer");
    // check iframe already added to the DOM
    if (f) {
        return;
    }
    var f = document.createElement("iframe");
    f.id = "makecoderenderer";
    f.style.position = "absolute";
    f.style.left = 0;
    f.style.bottom = 0;
    f.style.width = "1px";
    f.style.height = "1px";
    f.src = "https://makecode.microbit.org/--docs?render=1"
    document.body.appendChild(f);
}

export default function renderSnippets() {
    let pendingPres = document.querySelectorAll('[data-makecode] pre');
    if (pendingPres.length == 0) {
        return;
    }

    let rendersLeft = pendingPres.length;

    const renderPre = (pre) => {
        if (!pre.id) pre.id = Math.random();
        let f = document.getElementById("makecoderenderer");
        f.contentWindow.postMessage({
            type: "renderblocks",
            id: pre.id,
            code: pre.innerText,
            options: {
                packageId: pre.getAttribute("pub")
            }
        }, "https://makecode.microbit.org/");
    }

    // listen for messages
    window.addEventListener("message", function (ev) {
        var msg = ev.data;
        if (msg.source != "makecode") return;

        switch (msg.type) {
            case "renderready":
                // renderer is ready, push all elements to iframe
                pendingPres.forEach(renderPre)
                break;
            case "renderblocks":
                var svg = msg.svg; // this is an string containing SVG
                var id = msg.id; // this is the id you sent
                // replace text with svg
                var img = document.createElement("img");
                img.src = msg.uri;
                img.width = msg.width;
                img.height = msg.height;
                var code = document.getElementById(id);
                code.parentElement.insertBefore(img, code)
                code.parentElement.removeChild(code);
                rendersLeft--;
                if (!rendersLeft) {
                    // the code blocks mess with the height, so we need to reset
                    resetAccordions(false);
                }
                break;
        }
    }, false);

    injectRenderer();
}